body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#default-prompt .MuiOutlinedInput-notchedOutline {
  border: 1px solid #F1F4FA !important;
}

#wws .MuiCardHeader-avatar {
  position: absolute;
  top: 20px;
}

.chat-input {
  width: calc(100% - 20px) !important;
  margin: 0 auto !important;
  padding-bottom: 12px !important;
  padding-top: 12px !important;
}

/* LAYOUT STYLING */
.card-header {
  background: linear-gradient(45deg, #000000 0%, var(--header-background-color, #E9E7FF) 100%) !important;
  padding: 20px !important;
  height: 200px;
  border-bottom: none;
  align-items: start !important;
}

.card-header-title {
  color: var(--header-text-color, #ffffff);
  font-weight: 600;
  font-size: 27px;
  line-height: 32.4px;
}

.card-header-title h4 {
  margin-top: 20px;
}

.home-content {
  position: relative;
  padding: 0 !important;
  margin-top: -125px;
}

.home-content-list {
  min-height: 274px;
  padding: 10px;
  gap: 10px;
  overflow-y: auto;
}

.chat-content {
  overflow-y: scroll;
  position: relative;
  padding: 0 !important;
  margin-top: 0;
}

/* HOME STYLING */
.home-container {
  padding: 10px;
}

/* CHAT STYLING */
.chat-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.send-message-textfield {
  border-radius: 16px !important;
  min-height: 56px;
}

/* CHAT HISTORY STYLING */
.chat-history {
  height: 100%;
  min-height: 310px;
  padding: 20px;
  overflow-y: auto;
}

.messages-ref-container {
  padding-top: 20px;
}

/* TYPING INDICATOR */
.typing-indicator-container {
  padding: 10px 21px 0 21px;
}

.typing-indicator-body {
  display: flex;
  align-items: end;
}

.dot {
  width: 6px;
  height: 6px;
  margin: 0 2px;
  background-color: #555;
  border-radius: 50%;
  animation: dot-blink 1.4s infinite both;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dot-blink {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

/* CONVERSATIONS STYLING */
.conversations-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.conversations-body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100vh;
}

.conversations-list-button {
  border-bottom: 1px solid lightgrey !important;
}

.conversations-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin: 15px 10px 10px 10px !important;
}

.conversations-stack {
  display: flex;
  align-items: center;
}

.conversation-assigned-agents-name {
  color: grey;
}

.conversation-time {
  color: grey;
}

/* ARTICLES STYLING */
.articles-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.articles-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin: 15px 10px 10px 10px !important;
}

.articles-body {
  flex-grow: 1;
}

/* HEADER STYLING */
.basic-header {
  background: white;
  padding: 20px !important;
  height: 35px;
  border-bottom: 1px solid lightgrey;
}

.basic-header .MuiCardHeader-avatar {
  margin-right: 0 !important;
}

.basic-header-title {
  text-align: center;
}

.agents-info {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* AVATAR STACK STYLING */
.styled-avatar {
  /* border: 2px solid white !important; */
  object-fit: cover !important;
  height: 36px !important;
  width: 36px !important;
}

.avatars-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.avatars-container>*:not(:first-child) {
  margin-left: -15px !important;
}

.more-avatar {
  border: 2px solid white !important;
  background-color: rgb(233, 231, 255) !important;
  color: black !important;
}

.agent-name {
  color: black;
}

.agent-presence {
  color: grey;
}

/* NAVIGATION STYLING */
.basic-navigation-list {
  border-radius: 10px;
  background: white;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.basic-navigation-stack {
  display: flex;
  align-items: center;
}

.recent-conversation-title {
  padding: 10px 0 0 15px;
  font-weight: bold !important;
}

.recent-conversation-stack {
  color: grey;
}

/* RECEIVER STYLING */
.reciever-ui {
  width: 100%;
  margin-top: 10px !important;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.receiver-info {
  display: flex;
  align-items: center;
  align-self: flex-start;
  gap: 5px;
}

.receiver-info.animate-fade-in {
  animation: fadeIn 0.5s ease-in;
}

.reciever-message-container.animate-fade-in-left {
  animation: fadeInLeft 0.5s ease-out;
}

.reciever-message-container.full-width {
  width: 100% !important;
}

.reciever-message-container.fit-content-width {
  width: fit-content !important;
  max-width: 80%;
}

.agent-avatar {
  background-color: white !important;
  height: 21px !important;
  width: 21px !important;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* SENDER STYLING */
.sender-ui {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin: 0;
}

.sender-ui > .sender-message-container{
  display: inline-block;
  width: auto;
  height: auto;
  width: fit-content;
  max-width: calc(100vh - 60px);
  word-wrap: break-word;
  position: relative;
  align-self: flex-end;
  padding: 1rem;
  margin-top: 5px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #344054;
}

.sender-message-container {
  animation: fadeInUp 0.5s ease-out;
}

.sender-message-text {
  color: white;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* FOOTER STYLING */
.footer-box {
  background-color: #f5f8fd;
  padding: 16px !important;
}

.footer-content {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.footer-text {
  color: black;
}

.footer-link {
  color: inherit;
  text-decoration: none;
}

/* VERIFIED CONTAINER */
.un-verified-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.doc-iframe{
  height: 100vh;
}
/* smartphones, Android phones, landscape iPhone */
@media (max-width:480px) {
  .widget-root {
    border-radius: 0 !important;
    height: 100vh;
  }
  
  #iframeWidgetContainer{
    width: 100vw !important
  }
  .home-content {
    height: calc(100vh - 165px);
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .chat-content {
    height: calc(100vh - 130px);
  }
}